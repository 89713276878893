import './App.css';

import { IonApp } from '@ionic/react';
import axios from 'axios';
import ls from 'local-storage';
import { isMobile } from 'mobile-device-detect';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import CacheBuster from 'react-cache-buster';
import OneSignal from 'react-onesignal';
import { BrowserRouter as Router } from 'react-router-dom';

import BarcodeScanModal from '@/components/BarcodeScanModal';
import CreateAdModal from '@/components/CreateAdModal';
import CreateEventModal from '@/components/CreateEventModal';
import Freshchat from '@/components/Freshchat';
import Lightbox from '@/components/Lightbox';
import PromoteDialog from '@/components/PromoteDialog';
import PushNotificationPrompt from '@/components/PushNotificationPrompt';
import UploadStatus from '@/components/UploadStatus';
import firebaseApp from '@/config/firebase.config';
import { getNotifications } from '@/pages/notifications/content';
import { config } from '@/settings';
import { AppStore, UserStore } from '@/stores';
import ErrorBoundary from '@/templates/error-boundary';
import PageWrap from '@/templates/page-wrap';
import { initAxiosInterceptor, initAxiosRetry } from '@/utils/axiosAuthHelper';
import { UploadProvider } from '@/utils/useUploadStatus';

import packageJson from '../package.json';

if (!isMobile && window.FreshworksWidget) {
  window.FreshworksWidget('show', 'launcher');
}

const env = process.env.NODE_ENV || 'development';

const App: FC = () => {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);

  const showAdModal = AppStore.useState((s) => s.showAdModal);
  const adModalProps = AppStore.useState((s) => s.adModalProps);
  const showEventModal = AppStore.useState((s) => s.showEventModal);
  const eventModalProps = AppStore.useState((s) => s.eventModalProps);
  const showPromoteModal = AppStore.useState((s) => s.showPromoteModal);
  const promoteModalProps = AppStore.useState((s) => s.promoteModalProps);
  const showInventoryScannerModal = AppStore.useState(
    (s) => s.showInventoryScannerModal
  );

  const [showPushNotificationPrompt, setShowPushNotificationPrompt] =
    useState(false);

  const onesignalInitializingRef = useRef(false);

  const initializeFirebaseAuthListener = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('User is logged in!');
        UserStore.update((s) => {
          s.firebaseUser = user;
        });
        firebaseApp
          .auth()
          .currentUser?.getIdToken()
          .then((tokenId) => {
            console.log('Got new token ID from App.jsx:');
            console.log(tokenId);
            ls('boxpressd_token_id', tokenId);
            axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('User is logged out!');
        // FIXME This doesn't account for users that never actually signed in
        // this.props.signOutUser(() => {
        //   window.location.replace(`${config.authEndPoint}/login?path=${window.location.href.split('?')[0]}&expired=true`);
        // });
      }
    });
  };

  const initBranch = () => {
    // FIXME See Boxpressd Shop code?
    // if (branch) {
    //   branch.init(config.branch.api_key);
    // }
  };

  const initOneSignal = async () => {
    if (!onesignalInitializingRef.current) {
      onesignalInitializingRef.current = true;
      await OneSignal.init({
        appId: config.onesignal.app_id,
        // safariWebId: 'web.onesignal.auto.37e682f0-44ba-408e-8045-bbc89f9e01bb',
        notifyButton: { enable: false },
        // serviceWorkerParam: { scope: '/onesignal' },
        allowLocalhostAsSecureOrigin: env === 'development',
        autoResubscribe: true,
        autoRegister: true,
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: 'smsAndEmail',
                autoPrompt: false,
              },
              {
                type: 'push',
                autoPrompt: false,
              },
            ],
          },
        },
      });
    }
    console.log(
      'OneSignal initialized with permission: ',
      OneSignal.Notifications.permission
    );
    // FIXME If the user chooses "Not now" on the prompt, there should be a flag to prevent this from showing again
    // if (!OneSignal.Notifications.permission) {
    //   setShowPushNotificationPrompt(true);
    // }
    OneSignal.Slidedown.addEventListener('slidedownShown', () => {
      console.log('Slidedown shown');
      setShowPushNotificationPrompt(true);
    });
  };

  useEffect(() => {
    initializeFirebaseAuthListener();
    initAxiosInterceptor();
    initAxiosRetry();
    initBranch();
    initOneSignal();
  }, []);

  useEffect(() => {
    if (selectedProfile && selectedProfileType) {
      getNotifications(selectedProfile, selectedProfileType);
    }
  }, [selectedProfile, selectedProfileType]);

  return (
    <ErrorBoundary>
      {/* <NetworkErrorBoundary> */}
      <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={env !== 'dev'}
        isVerboseMode={false}
      >
        <Router>
          <UploadProvider>
            <div id="App">
              <IonApp>
                <PageWrap />
                <CreateAdModal open={showAdModal} {...adModalProps} />
                <CreateEventModal open={showEventModal} {...eventModalProps} />
                <PromoteDialog open={showPromoteModal} {...promoteModalProps} />
                <BarcodeScanModal open={showInventoryScannerModal} />
                <Freshchat />
                <Lightbox />
                <UploadStatus />
                {/* FIXME onClose should probably set a timer or similar to re-prompt or set a flag to not prompt again */}
                <PushNotificationPrompt
                  open={showPushNotificationPrompt}
                  onClose={() => setShowPushNotificationPrompt(false)}
                />
              </IonApp>
            </div>
          </UploadProvider>
        </Router>
      </CacheBuster>
      {/* </NetworkErrorBoundary> */}
    </ErrorBoundary>
  );
};

export default App;
